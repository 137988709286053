exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Restore bootstrap heading margins because our stylesheet is :shit: */\n.W6jC9ujhXfczmdLQ3mReI h1, .W6jC9ujhXfczmdLQ3mReI h2, .W6jC9ujhXfczmdLQ3mReI h3 {\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n.W6jC9ujhXfczmdLQ3mReI h4, .W6jC9ujhXfczmdLQ3mReI h5, .W6jC9ujhXfczmdLQ3mReI h6 {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"bootstrapHeading": "W6jC9ujhXfczmdLQ3mReI"
};