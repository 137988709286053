exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2gvksBsUyJr20QPpvxjFrN {\n    word-break: break-word;\n}\n\n._2gvksBsUyJr20QPpvxjFrN time {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n", ""]);

// exports
exports.locals = {
	"fieldsRowField": "_2gvksBsUyJr20QPpvxjFrN"
};