exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3yUXB5gkIIyfuke4-Oq0_p {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n", ""]);

// exports
exports.locals = {
	"dropdownheader": "_3yUXB5gkIIyfuke4-Oq0_p"
};