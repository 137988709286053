exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1DF9OEWZsFmavjw0hc5cKT {\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 5px;\n    margin-bottom: 5px;\n    font-weight: 600;\n}\n\n._2WqpDrVsctwMkTvjR1RenM {\n    margin-bottom: 10px;\n    list-style: none;\n}\n\n._3xJE8pDtM2A6VLE8McsDPC {\n    margin-top: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"dropdownheader": "_1DF9OEWZsFmavjw0hc5cKT",
	"bottomSpacer": "_2WqpDrVsctwMkTvjR1RenM",
	"topSpacer": "_3xJE8pDtM2A6VLE8McsDPC"
};