exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1pWtN2smmslj18KMvMfpHi {\n    min-width: fit-content;\n    opacity: 0.5;\n    z-index: 999999;\n}\n", ""]);

// exports
exports.locals = {
	"draggedElement": "_1pWtN2smmslj18KMvMfpHi"
};