exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1NV_4c-tLEbIrZSSdGqwt2 {\n    padding-left: 0;\n    margin-bottom: 20px;\n}\n\n._1NV_4c-tLEbIrZSSdGqwt2 ._1NV_4c-tLEbIrZSSdGqwt2 {\n    margin-bottom: 0;\n}\n", ""]);

// exports
exports.locals = {
	"list": "_1NV_4c-tLEbIrZSSdGqwt2"
};