exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ARMU8sr57lgXyETTq4ykn {\n    float: right;\n    margin-top: 10px;\n}\n\n.BeIYLXlOL62RUTqUAagJW {\n    float: right;\n    margin-top: 10px;\n}\n\n._103dnm9iUcHwOLBnpLUrS6 .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n._103dnm9iUcHwOLBnpLUrS6:not(._23kQi-HmMg0Z8aJsqZ21ji) .description {\n    margin-left: 20px;\n}\n\n._103dnm9iUcHwOLBnpLUrS6 .form-group {\n    margin-bottom: 0;\n}\n\n._23kQi-HmMg0Z8aJsqZ21ji {\n    margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"headerComponentsWrapper": "ARMU8sr57lgXyETTq4ykn",
	"itemActionsWrapper": "BeIYLXlOL62RUTqUAagJW",
	"itemWrapper": "_103dnm9iUcHwOLBnpLUrS6",
	"itemWrapperStatic": "_23kQi-HmMg0Z8aJsqZ21ji"
};