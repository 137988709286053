exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2V7NyG3-sz-_pi-q2WPQDK {\n    grid-column: 1;\n    grid-row: 1;\n    -ms-grid-column: 1;\n    -ms-grid-row: 1;\n    font-size: 1.125rem; /* theme.fonts.size.large */\n    text-overflow: ellipsis;\n    /* Usign paddding instead of margin / grid-gap because of IE11 grid compatibility */\n    padding-bottom: 5px;\n}\n\n.dNcD3mzbtUsv8e878HOYs {\n    position: relative;\n    z-index: 1;\n}\n\n._2W0Xm5qXng5izRm-cKXp5d {\n    cursor: move;\n    opacity: 0.3;\n}\n", ""]);

// exports
exports.locals = {
	"widgetHeader": "_2V7NyG3-sz-_pi-q2WPQDK",
	"widgetActionDropdown": "dNcD3mzbtUsv8e878HOYs",
	"widgetDragHandle": "_2W0Xm5qXng5izRm-cKXp5d"
};