exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1EVWuOZol7iFIJ22F5Flgw {\n    display: flex;\n    justify-content: space-between;\n}\n\n._13tV2nwBD3BEUDpsdRKYZ {\n    line-height: 1.5;\n}\n\n._2KT3bw8y5vn98VutwYkwa7 {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n._3V6CeSamqqq-52Oi2DI3P8 {\n    margin-right: 5px;\n    width: 100%;\n}\n\n.N19uTn2tKBEh1RBLD4BDc {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding-top: 0px;\n    padding-right: 0px;\n    float: right;\n}\n\n._3_-amxljfrVWpP_4ngmdLL {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n\n._13-qimgssqmaamCi_nnVWm {\n    height: 20px;\n}\n\n.g__9NFKXFSF3EruPdFzOC {\n    display: inline-block;\n    overflow: visible;\n    width: 100%;\n}\n\n._2wLL-a-qmWggbm1CEkRyCl {\n    margin-top: 2em;\n}\n", ""]);

// exports
exports.locals = {
	"decoratorBox": "_1EVWuOZol7iFIJ22F5Flgw",
	"decoratorType": "_13tV2nwBD3BEUDpsdRKYZ",
	"addDecoratorButtonContainer": "_2KT3bw8y5vn98VutwYkwa7",
	"addDecoratorSelect": "_3V6CeSamqqq-52Oi2DI3P8",
	"helpLink": "N19uTn2tKBEh1RBLD4BDc",
	"helpPopover": "_3_-amxljfrVWpP_4ngmdLL",
	"helpLinkContainer": "_13-qimgssqmaamCi_nnVWm",
	"decoratorListContainer": "g__9NFKXFSF3EruPdFzOC",
	"noDecoratorsAlert": "_2wLL-a-qmWggbm1CEkRyCl"
};