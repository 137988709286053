exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".kBxfMgU-UK22A3gPEOXSr {\n    height: 100%;\n    /* The grid prop is a workaround to fix\n     * horizontal scrolling for safari */\n    display: grid;\n    display: -ms-grid;\n    grid-template-rows: 1fr;\n    -ms-grid-rows: 1fr;\n    grid-template-columns: 1fr;\n    -ms-grid-columns: 1fr;\n}\n\n._1P9QCAO43Tncg729_NI4-- {\n    overflow: auto;\n    grid-row: 1;\n    -ms-grid-row: 1;\n    grid-column: 1;\n    -ms-grid-column: 1;\n}\n\n._2fPgAu6b0nwZbuyuw6whVF {\n    left: 0px;\n}\n\n@media print {\n    table.messages {\n        font-size: 1rem !important; /* theme.fonts.size.body */\n        min-width: 50%;\n        padding-left: 0;\n    }\n\n    table.messages th {\n        font-weight: bold !important;\n        font-size: inherit !important;\n    }\n\n    table.messages th,\n    table.messages td {\n        border: 1px #ccc solid !important;\n        left: 0;\n        padding: 5px;\n        position: static;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"container": "kBxfMgU-UK22A3gPEOXSr",
	"scrollContainer": "_1P9QCAO43Tncg729_NI4--",
	"leftAligned": "_2fPgAu6b0nwZbuyuw6whVF"
};