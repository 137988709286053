exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2KZUiUWrvWBFPRy29xYVK7 {\n    height: 80vh;\n    width: 80vw;\n    margin-top: 20px;\n}\n\n.NC2KOFDZLwqfG809c1VQk {\n    width: 80vw;\n}\n\n._2vwRxf2DcD5JZ4L1mYIPAj {\n    padding: 15px 20px 15px 20px;\n}\n\n._1OtzAo-P7_UObrnhVSD-_S {\n    display: grid;\n    display: -ms-grid;\n    height: 100%;\n    grid-template-columns: 1fr;\n    -ms-grid-columns: 1fr;\n    grid-template-rows: auto minmax(200px, 1fr) auto;\n    -ms-grid-rows: auto minmax(200px, 1fr) auto;\n    grid-template-areas: \"Query-Controls\" \"Visualization\" \"Footer\";\n}\n\n._2LYYZCVpn8KOHcrQeeVAb7 {\n    grid-area: Query-Controls;\n    grid-column: 1;\n    -ms-grid-column: 1;\n    grid-row: 1;\n    -ms-grid-row: 1;\n}\n\n._2at144Njr8RXES-9Qoh5Fi {\n    grid-area: Visualization;\n    overflow: hidden;\n    grid-column: 1;\n    -ms-grid-column: 1;\n    grid-row: 2;\n    -ms-grid-row: 2;\n}\n\n._3LyEBN4C2b31ykcXeMv6aW {\n    grid-area: Footer;\n    grid-column: 1;\n    -ms-grid-column: 1;\n    grid-row: 3;\n    -ms-grid-row: 3;\n}\n", ""]);

// exports
exports.locals = {
	"editWidgetDialog": "_2KZUiUWrvWBFPRy29xYVK7",
	"editWidgetControls": "NC2KOFDZLwqfG809c1VQk",
	"editWidgetControlsContent": "_2vwRxf2DcD5JZ4L1mYIPAj",
	"gridContainer": "_1OtzAo-P7_UObrnhVSD-_S",
	"QueryControls": "_2LYYZCVpn8KOHcrQeeVAb7",
	"Visualization": "_2at144Njr8RXES-9Qoh5Fi",
	"Footer": "_3LyEBN4C2b31ykcXeMv6aW"
};