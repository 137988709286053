exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2MBMGq5BooeSjjkHadluWU {\n    display: inline;\n}\n", ""]);

// exports
exports.locals = {
	"inlineForm": "_2MBMGq5BooeSjjkHadluWU"
};