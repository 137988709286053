exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._4wOQrBrd3Gk0Llp0yLdno {\n    min-width: fit-content;\n}\n\n", ""]);

// exports
exports.locals = {
	"useFullWidth": "_4wOQrBrd3Gk0Llp0yLdno"
};