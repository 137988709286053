exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jekyEqSB3L5Tv7p08J3ia {\n    padding-left: 0;\n    padding-right: 0;\n}\n\n.jekyEqSB3L5Tv7p08J3ia .popover-content {\n    min-width: 200px;\n    padding: 0;\n}\n\n.jekyEqSB3L5Tv7p08J3ia .list-group {\n    margin-bottom: 0;\n}\n\n.jekyEqSB3L5Tv7p08J3ia .list-group-item {\n    border-right: 0;\n    border-left: 0;\n    padding: 6px 15px;\n}\n\n.jekyEqSB3L5Tv7p08J3ia .list-group-item:first-child {\n    border-top-right-radius: 0;\n    border-top-left-radius: 0;\n}\n\n.jekyEqSB3L5Tv7p08J3ia .list-group-item:last-child {\n    border-bottom: 0;\n}\n\n._1Y0kp3bDzOuc3SwxqHJZP_ {\n    max-height: 340px; /* 10 items */\n    overflow: auto;\n}\n\n.NE9IfOfd8cJa0QwfDHo8z {\n    margin-bottom: 0 !important;\n    padding: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"customPopover": "jekyEqSB3L5Tv7p08J3ia",
	"scrollableList": "_1Y0kp3bDzOuc3SwxqHJZP_",
	"dataFilterInput": "NE9IfOfd8cJa0QwfDHo8z"
};