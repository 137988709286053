exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "div.plotly-notifier {\n    visibility: hidden;\n}\n\n._2eH1uNgSIBGCBt1YKOM8Fm .popover-content {\n    padding: 0;\n}\n", ""]);

// exports
exports.locals = {
	"customPopover": "_2eH1uNgSIBGCBt1YKOM8Fm"
};