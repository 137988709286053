exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PGZKhgo-1VUrTUVR_MCSq {\n    display: inline-block;\n    width: 100%;\n}\n\n.YzG4xa0irqvZaI-RLWJSj {\n    float: left;\n    margin-right: 10px;\n}", ""]);

// exports
exports.locals = {
	"listGroupItem": "_3PGZKhgo-1VUrTUVR_MCSq",
	"itemHandle": "YzG4xa0irqvZaI-RLWJSj"
};